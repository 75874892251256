<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="div">
            <div class="login">
                <div @click="login">
                    账号登录
                </div>
                <div class="login-active">
                    验证码登录
                </div>
            </div>
            <div class="login-input">
                <input type="number" placeholder="请输入手机号" v-model="phone" class="name-input" maxlength="11">
            </div>
            <div class="login-input">
                <input type="text" placeholder="请输入验证码" v-model="code" class="name-input">
                <div class="login-code" :class="code_show?'code-active':''" @click="codeClick">
                    {{yzmText}}
                </div>
            </div>
            <div class="login-button button-active" v-if="display" @click="LoginClick">
                登录
            </div>
            <div class="login-button" v-else>
                登录
            </div>
            <div class="login-left">
                <div class="login-right" @click="forget">忘记密码</div>
                <div class="login-right" @click="registered">新用户注册</div>
            </div>

            <div class="login-fixed">
                <div class="login-fixed-div">
                    <div class="weixin" @click="weixin" v-if="userAgent">
                        <img src="../../assets/images/login-wexin.png" alt="" class="login-weixn">
                        <div>微信一键登录</div>
                    </div>
                    <!--                    <div class="weixin" @click="qq" id="qqLogin" v-if="!userAgent">-->
                    <!--                        <img src="../../assets/images/login-QQ.png" alt="" class="login-weixn">-->
                    <!--                        <div>QQ一键登录</div>-->
                    <!--                    </div>-->
                </div>
                <div class="login-text">登录视为您已阅读并同意
                    <span class="blue" @click="userClick">用户协议</span>
                    和
                    <span class="blue" @click="privacyClick">隐私政策</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoginCode',
        data() {
            return {
                phone: '',
                code: '',
                display: false,
                code_show: false,
                yzmText: '获取验证码',
                time: 60,
                userAgent: true,
            }
        },
        created() {
            if (/MicroMessenger/.test(window.navigator.userAgent)) {
                //在微信中
                this.userAgent = true
            } else {
                //在浏览器中打开
                this.userAgent = false
            }
        },
        watch: {
            phone(e) {
                if (e.length == 11) {
                    if (this.time == 60) {
                        this.code_show = true
                    }
                } else {
                    this.code_show = false
                }
                if (e.length == 11 && this.code) {
                    this.display = true;
                } else {
                    this.display = false;
                }
            },
            code(e) {
                if (e && this.phone.length == 11) {
                    this.display = true;
                } else {
                    this.display = false;
                }
            }
        },
        methods: {
            userClick() {
                this.$router.push({
                    path: '/agreement'
                })
            },
            privacyClick() {
                this.$router.push({
                    path: '/privacy'
                })
            },
            LoginClick() {
                this.$axios.post("/api/quick_login", {
                    phone: this.phone,
                    code: this.code,
                })
                    .then(res => {
                        if (res.data.code == 200) {
                            localStorage.setItem("token", res.data.data.access_token)
                            this.$toast.success('登录成功');
                            this.$router.replace({
                                path: '/library'
                            })
                        }
                    })
                    .catch(err => {
                        this.$toast(err.response.data.message);
                    })
            },
            codeClick() {
                if (!this.code_show) return
                this.$ajax({
                    method: 'POST',
                    url: '/api/common/sms_send',
                    dataType:'json',
                    data: {'mobile':this.phone,'type':'quick_login'}
                }).then(res => {
                     let timer = setInterval(() => {
                          if (this.time == 0) {
                              clearInterval(timer);
                              this.yzmText = '获取验证码';
                              this.code_show = true;
                              this.time = 60
                          } else {
                              this.yzmText = this.time + '秒后再次获取';
                              this.code_show = false;
                              this.time--;
                          }
                     }, 1000)
                  this.$toast(res.data.message);
                }).catch(err => {
                    this.$toast('发送失败');
                })
               

              
            },
            login() {
                this.$router.replace({
                    path: '/login'
                })
            },
            registered() {
                this.$router.push({
                    path: "/loginregistered"
                })
            },
            forget() {
                this.$router.push({path: "/loginforget"})
            },
            weixin() {
                this.$toast('微信登录')
            },
            // 浏览器QQ登录
            // qq() {
            //     this.$toast('qq登录')
            //     QC.Login.showPopup({
            //             appId: '101896125',
            //             redirectURI: 'http://localhost:8080/library'
            //         }
            //     )
            // },
        }
    }
</script>

<style scoped>
    .login-fixed-div {
        display: flex;
        justify-content: space-around;
        width: 500px;
    }

    .code-active {
        color: #4D77FD !important;
    }

    .login-code {
        border-left: 1px solid #EDEFF2;
        padding: 0 20px;
        text-align: center;
        width: 400px;
        font-size: 30px;
        color: #909399;
    }

    .login-text {
        font-size: 20px;
        color: #909399;
    }

    .weixin {
        text-align: center;
        color: #BFC2CC;
        font-size: 20px;
        margin-bottom: 60px;
    }

    .login-weixn {
        width: 107px;
        height: 128px;
    }

    .login-fixed {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .blue {
        border-bottom: 1px solid #4D77FD;
        color: #4D77FD;
    }

    .login-right {
        color: #303133;
        font-size: 24px;
    }

    .login-left {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }

    .button-active {
        background-color: #4D77FD !important;
        opacity: 1 !important;
    }

    .login-button {
        height: 88px;
        background-color: #4D77FD;
        opacity: .5;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 44px;
        margin-top: 70px;
    }

    .name-input::-webkit-input-placeholder {
        color: #BFC2CC;
    }

    .login-input {
        border-bottom: 1px solid #EDEFF2;
        padding: 20px 0;
        margin-top: 40px;
        color: #303133;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .login-input input {
        width: 100%;
        font-size: 30px;
    }

    .login-active {
        font-size: 48px;
        margin-left: 60px;
        color: #0A1C33;
    }

    .login {
        display: flex;
        align-items: center;
        font-size: 30px;
        color: #606266;
        padding: 60px 0;
    }
</style>
